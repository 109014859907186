import React from 'react'
import {rocketService} from '~root/_services'

export default function Chat() {
    return (
        <div className="d-flex flex-row" style={{height:"100vh"}}>
        <div className="d-flex" style={{flex:3}}>
            <iframe src="https://hxspace.rocket.chat/channel/testowy2" width="100%" height="100%"/>
        </div>
        <div className="d-flex" style={{flex:7, backgroundColor:"#2f343d"}}>
        <iframe src="https://player.vimeo.com/video/535814873" width="100%" height="100%" frameborder="0" title="Sample video" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
        </div>
        </div>
    )
}
