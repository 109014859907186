export const CHANGE_COLOR_TO_ORANGE = 'CHANGE_COLOR_TO_ORANGE';
export const CHANGE_COLOR_TO_BLUE = 'CHANGE_COLOR_TO_BLUE';
export const CHANGE_COLOR_TO_GREEN = 'CHANGE_COLOR_TO_GREEN';
export const CHANGE_COLOR_TO_RED = 'CHANGE_COLOR_TO_RED';
export const CHANGE_COLOR_TO_VAILLANT = 'CHANGE_COLOR_TO_VAILLANT';
export const CHANGE_COLOR_TO_SAUNIER = 'CHANGE_COLOR_TO_SAUNIER';

export function changeColorToOrange() {
  return {
    type: CHANGE_COLOR_TO_ORANGE,
  };
}

export function changeColorToBlue() {
  return {
    type: CHANGE_COLOR_TO_BLUE,
  };
}

export function changeColorToGreen() {
  return {
    type: CHANGE_COLOR_TO_GREEN,
  };
}

export function changeColorToRed() {
  return {
    type: CHANGE_COLOR_TO_RED,
  };
}  
export function changeColorToSaunier() {
  return {
    type: CHANGE_COLOR_TO_SAUNIER,
  };
}  
export function changeColorToVaillant() {
  return {
    type: CHANGE_COLOR_TO_VAILLANT,
  };
}  