import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { roomService } from "~root/_services/room.service";
import { meetingService } from "~root/_services/meeting.service";
import ColoredCircle from "~root/shared/components/ColoredCircle";

class TopbarBbbServerStatus extends PureComponent {
  constructor() {
    super();
    this.state = {
      serverStatus: false,
      numberOfMeetings: "#",
    }
  }
  componentDidMount = async () => {
    const resBBB = await roomService.checkBbbStatus();
    this.setState({ serverStatus: resBBB.status });

    const resMeetings = await meetingService.getAllMeetings();
    let number;
    if (Array.isArray(resMeetings)) {
      number = resMeetings.length;
    } else if (typeof resMeetings === "object") {
      number = 1;
    } else {
      number = 0;
    }
    this.setState({numberOfMeetings: number});
  }

  render() {
    const { t } = this.props;
    return (
      <div className="topbar__profile" >
        <div
          className="topbar__avatar"
          style={{ alignItems: "center", paddingRight: "25px" }}
        >
          <p>{t("topbar.server-status")} {this.state.serverStatus ? <ColoredCircle color={"green"} /> : <ColoredCircle color={"red"} />}</p>
          <p className={'ml-2'} >{t("admin.current-meetings")}: {this.state.numberOfMeetings}</p>
        </div>
      </div>
    );
  }
}
export default withTranslation("common")(TopbarBbbServerStatus);
